
import BaseService from '@/services/BaseService'

class ImportWizardService extends BaseService {
  constructor() {
    super()
  }

  async getSourceList(opts = {}) {
    this.endPoint = 'api/v2/sources'
    return await this.get(opts)
  }

  async getMappedCategories(opts = {}) {
    this.endPoint = 'api/v2/source-categories'
    return await this.get(opts)
  }

  async getNestedAliCategory(opts = {}) {
    this.endPoint = '/api/v2/categories/nested'
    return await this.get(opts)
  }

  async getAliCategory(opts = {}) {
    this.endPoint = 'api/v2/categories'
    return await this.get(opts)
  }

  async updateAliCategory(id: any, data: any) {
    this.endPoint = 'api/v2/source-categories'
    return await this.putOne(id, data)
  }

  async getClient(opts = {}) {
    this.endPoint = 'api/v2/users/excludeadmin'
    return await this.get(opts)
  }

  async setSyncMapping(data: any){
    this.endPoint = 'api/v2/source-categories/sync-product-categories'
    return await this.post(data)
  }
}
export default new ImportWizardService()