import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import FormData from 'form-data'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
// import VTreeSelect from 'vue-treeselect'

// Vue Treeselect
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// Base Page
import BasePage from '@/pages/Base'

// Services
import SuppliersService from '@/services/Suppliers'
import ImportWizardService from '@/services/ImportWizard'
import EditProduct from '@/pages/Products/EditProduct/editProduct'
import { watch } from 'fs'


// @Component({
//     components: { VTreeSelect, Treeselect }
// })
@Component({
  components: { Treeselect }
})
export default class ImportWizard extends BasePage {
  constructor() {
    super()
  }

    // Search
    searchLoading: boolean = false
    searchInputUnmapped: string = ''
    searchInputMapped: string = ''
    searchLabel: string = 'Search Origin Category...'
    searchHint: string = 'Search Origin category'
    searchIcon: string = 'search'

    // Table
    tableHeaders: object[] = [
      {
        text: 'Origin Category',
        align: 'left',
        sortable: true,
        value: 'originCategory'
      },
      {
        text: 'ALI Category',
        align: 'center',
        sortable: false,
        value: 'aliCategory',
        width: '40%'
      },
      {
        text: 'Set Label',
        align: 'center',
        sortable: false,
        value: 'setLabel'
      },
      {
        text: ' ',
        align: 'center',
        sortable: false
      }
    ]
    tableMappedItems: object[] = []
    tableUnmappedItems: object[] = []
    tableTotalItems: number | string = 0
    tableUnmappedTotalItems: number | string = 0
    tableMappedTotalItems: number | string = 0
    tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
    tablePaginationUnmapped: any = {
      // sortBy: '-id',
      page: 1,
      rowsPerPage: 25,
      descending: true,
      totalItems: 0
    }
    tablePaginationMapped: any = {
      // sortBy: '-id',
      page: 1,
      rowsPerPage: 25,
      descending: true,
      totalItems: 0
    }
    tableLoading: boolean = false
    tableLoadingUnmapped: boolean = false
    tableLoadingMapped: boolean = false
    categoryLevel1List: any = []
    enabled: any = []
    //choose user
    chooseUser: any = {
      items: [],
      keyword: null,
      loading: false
    }
    searchUserDebounce: Function

    //source list
    sourceList: any = {
      items: [],
      keyword: null,
      loading: false
    }

    editCategory: any = {
      categoryLevel1: '',
      categoryAliMapped: [],
      categoryAliUnmapped: [],
      isChange: [],
      tags: []
    }
    categoryItems: any = []
    //   useAsEdit: boolean = false
    childsCategory: any = []
    // alwaysOpen: boolean = true



    @Watch('chooseUser.keyword')
    onChanged() {
      this.searchUserDebounce = debounce(this.getClient, 500)
      this.searchUserDebounce()
    }
    // @Watch('searchInputMapped', { deep: true })
    onChangeMapped() {
      this.getMappedCategories()
    }
    // @Watch('searchInputUnmapped', { deep: true })
    onChangeUnmapped() {
      this.getUnmappedCategories()
    }

    @Watch('tablePaginationUnmapped', { deep: true })
    async onChangedPaginationUnmapped() {
      this.$vuetify.goTo(0)
      this.getUnmappedCategories()
    }

    @Watch('tablePaginationMapped', { deep: true })
    async onChangedPaginationMapped() {
      this.$vuetify.goTo(0)
      this.getMappedCategories()
    }

    async mounted() {
      this.getSourceList()
      this.sourceList.source = 2
      // this.loadingData()
      this.getCategoryItems()
    }

    async getSourceList() {
      try {
        this.sourceList.loading = true
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 20,
            sort: '-created_at'
          }
        }
        const response = await ImportWizardService.getSourceList(opts)
        const sourceList = response.data.data
        for (const sourcelist of sourceList) {
          const source: any = {
            name: sourcelist.attributes.name,
            id: sourcelist.attributes.id
          }
          this.sourceList.items.push(source)
        }
        this.sourceList.items = sortBy(this.sourceList.items, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.sourceList.loading = false
      }
    }

    async getMappedCategories() {
      try {
        this.tableLoadingMapped = true
        // if (!isEmpty(this.filter.selected)) {
        const opts: any = {
          params: {
            'filter[source_id][is]': this.sourceList.source,
            'filter[mapped_category_id][null]': false,
            'page[num]': this.tablePaginationMapped.page,
            'page[limit]': this.tablePaginationMapped.rowsPerPage
          }
        }
        if (this.searchInputMapped != '') {
          opts.params = {
            ...opts.params,
            'filter[categories][like]': this.searchInputMapped
          }
        } else {
          var textFile = 'filter[categories][like]'
          delete opts.params[textFile]
        }
        const response = await ImportWizardService.getMappedCategories(opts)
        var responseData = response.data.data
        var responseMeta = response.data.meta
        this.tableMappedItems = []
        forEach(responseData, dataCategory => {
          var categories = ''
          var categories_array = dataCategory.attributes.categories
          var id_category = dataCategory.attributes.id
          forEach(categories_array, category => {
            if (categories != '') {
              categories = categories + ' > ' + category
            } else {
              categories = categories + category
            }
          })

          const category = dataCategory.attributes
          category.categories = categories
          console.log('data category ', dataCategory)

          this.editCategory.categoryAliMapped[id_category] = dataCategory.attributes.mapped_category_id
          this.editCategory.isChange[id_category] = false

          if (category.tags != null) {
            this.editCategory.tags[id_category] = category.tags
          } else {
            this.editCategory.tags[id_category] = []
          }
          this.tableMappedItems.push(category)
        })
        this.tableMappedTotalItems = responseMeta.pagination.total
        this.tableLoadingMapped = false
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingMapped = false
      }
    }

    async getUnmappedCategories() {
      const opts: any = {
        params: {
          'filter[source_id][is]': this.sourceList.source,
          'filter[mapped_category_id][null]': true,
          'page[num]': this.tablePaginationUnmapped.page,
          'page[limit]': this.tablePaginationUnmapped.rowsPerPage,
          sort: this.tablePaginationUnmapped.descending ? '-id' : 'id'
          // include: 'categories,supplier,source,variations,images,users'
        }
      }

      if (this.searchInputUnmapped != '') {
        opts.params = {
          ...opts.params,
          'filter[categories][like]': this.searchInputUnmapped
        }
      } else {
        var textFile = 'filter[categories][like]'
        delete opts.params[textFile]
      }

      this.tableLoadingUnmapped = true
      try {
        var response = await ImportWizardService.getMappedCategories(opts)

        var responseData = response.data.data
        var responseMeta = response.data.meta

        this.tableUnmappedItems = []
        forEach(responseData, dataCategory => {
          var categories = ''
          var categories_array = dataCategory.attributes.categories
          var id_category = dataCategory.attributes.id
          forEach(categories_array, category => {
            if (categories != '') {
              categories = categories + ' > ' + category
            } else {
              categories = categories + category
            }
          })

          const category = dataCategory.attributes
          category.categories = categories
          this.editCategory.categoryAliMapped[id_category] = dataCategory.attributes.mapped_category_id
          this.editCategory.isChange[id_category] = false

          if (category.tags != null) {
            this.editCategory.tags[id_category] = category.tags
          } else {
            this.editCategory.tags[id_category] = []
          }
          this.tableUnmappedItems.push(category)
        })
        this.tableUnmappedTotalItems = responseMeta.pagination.total

        this.tableLoadingUnmapped = false
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingUnmapped = false
      }
    }

    async loadingData() {
      this.showLoading({ text: 'Loading...' })
      await this.getMappedCategories()
      await this.getUnmappedCategories()
      this.closeLoading()
    }

    async getClient() {
      try {
        this.chooseUser.loading = true
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 25,
            sort: '-created_at',
            'filter[name][like]': ''
          }
        }
        if (!isEmpty(this.chooseUser.keyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.chooseUser.keyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await ImportWizardService.getClient(opts)
        const dataClient = response.data.data
        for (const cuser of dataClient) {
          const client: any = {
            name: cuser.attributes.name + ' (' + cuser.attributes.email + ')',
            id: cuser.attributes.id
          }
          this.chooseUser.items.push(client)
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.chooseUser.loading = false
      }
    }

    async getCategoryItems() {
      this.tableLoading = true
      try {
        var response = await ImportWizardService.getNestedAliCategory()
        var responseData = response.data

        forEach(responseData, dataCategory => {
          if (dataCategory.level === 1) {
            const category: any = {
              id: dataCategory.id,
              label: dataCategory.name,
              customLabel: dataCategory.name
            }

            if (!isEmpty(dataCategory.children)) {
              category.children = this.getChildCategoryItem(category.customLabel, dataCategory.children)
            }

            this.categoryItems.push(category)
          }
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoading = false
      }
    }

    getChildCategoryItem(labelCategory, categoryChildren) {
      var childs = []
      forEach(categoryChildren, dataChildren => {
        const childrens: any = {
          id: dataChildren.id,
          label: dataChildren.name,
          customLabel: labelCategory + ' > ' + dataChildren.name
        }
        if (!isEmpty(dataChildren.children)) {
          childrens.children = this.getChildCategoryItem(childrens.customLabel, dataChildren.children)
        }
        childs.push(childrens)
      })
      return childs
    }

    onChangeCategoryItems(id_category) {
      this.editCategory.isChange[id_category] = true
    }

    async setSyncMapping(props) {
      if (props.item.source_category_id) {
        try {
          this.showLoading({ text: 'Saving...' })
          const payload = {
            source_category_ids: [props.item.source_category_id]
          }
          await ImportWizardService.setSyncMapping(payload)
          this.closeLoading()
          this.showSnackbar({
            text: 'Category ALI Updated Successfully!',
            color: 'green',
            timeout: 2500
          })
          this.getUnmappedCategories()
          this.getMappedCategories()
        } catch (error) {
          this.catchHandler(error)
        }
      }
    }

    async updateAliCategoriesMapped(props) {
      try {
        this.showLoading({ text: 'Saving...' })
        var payload = {
          mapped_category_id: this.editCategory.categoryAliMapped[props.item.id],
          // tags: this.editCategory.tags[props.item.id]
          tags: []
        }

        if (!this.editCategory.categoryAliMapped[props.item.id]) {
          payload.mapped_category_id = null
        }

        if (!isEmpty(this.editCategory.tags[props.item.id])) {
          payload.tags = this.editCategory.tags[props.item.id]
        }
        await ImportWizardService.updateAliCategory(props.item.id, payload)
        this.closeLoading()
        this.showSnackbar({
          text: 'Category ALI Updated Successfully!',
          color: 'green',
          timeout: 2500
        })
        this.getMappedCategories()
        this.setSyncMapping(props)
      } catch (error) {
        this.catchHandler(error)
      }


      // old
      // if (this.editCategory.categoryAliMapped[props.item.id] !== undefined || !isEmpty(this.editCategory.tags[props.item.id])) {
      //     try {
      //         this.showLoading({ text: 'Saving...' })
      //         const payload = {
      //             mapped_category_id: this.editCategory.categoryAliMapped[props.item.id],
      //             tags: this.editCategory.tags[props.item.id]
      //         }
      //         console.log('payload',payload)
      //         await ImportWizardService.updateAliCategory(props.item.id, payload)
      //         this.closeLoading()
      //         this.showSnackbar({
      //             text: 'Category ALI Updated Successfully!',
      //             color: 'green',
      //             timeout: 2500
      //         })
      //         this.getMappedCategories()
      //         this.setSyncMapping(props)
      //     } catch (error) {
      //         this.catchHandler(error)
      //     }
      // } else {
      //     console.log('else')
      //     this.showSnackbar({
      //         text: 'Please fill all the required field(s)',
      //         color: 'red',
      //         timeout: 3500
      //     })
      // }
    }

    async updateAliCategoriesUnmapped(props) {
      if (this.editCategory.categoryAliUnmapped[props.item.id] !== undefined || !isEmpty(this.editCategory.tags[props.item.id])) {
        try {
          this.showLoading({ text: 'Saving...' })
          const payload = {
            mapped_category_id: this.editCategory.categoryAliUnmapped[props.item.id],
            tags: []
          }

          if (!isEmpty(this.editCategory.tags[props.item.id])) {
            payload.tags = this.editCategory.tags[props.item.id]
          }

          await ImportWizardService.updateAliCategory(props.item.id, payload)
          this.closeLoading()
          this.showSnackbar({
            text: 'Category ALI Updated Successfully!',
            color: 'green',
            timeout: 2500
          })
          this.getUnmappedCategories()
          this.getMappedCategories()
          // this.setSyncMapping(props)
        } catch (error) {
          this.catchHandler(error)
        }
      } else {
        this.showSnackbar({
          text: 'Please fill all the required field(s)',
          color: 'red',
          timeout: 3500
        })
      }
    }
}